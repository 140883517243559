<template>
  <section class="tappay-setting" :v-loading="loading">
    <PageTitle
      title="iPhone 卡緊收參數設定"
      icon="chevron_left"
      style="padding-bottom: 24px"
      hideBtn
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <section class="section-block">
      <SectionTitle title="iPhone 卡緊收參數設定" fontSize="18" @edit="modal.edit = true" />
      <BaseElForm label-position="left" label-width="250px">
        <BaseElFormItem v-for="field in displayData" :key="field.label" :label="field.label">
          <div class="text-[#5E5E5E]">
            {{ field.value }}
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </section>
    <TapPayEditModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { get, map } from 'lodash'
import { useShop } from '@/use/shop'
import { GetTapPayConfig, FindAllowBindUserList } from '@/api/tapPay'
import TapPayEditModal from './components/TapPayEditModal.vue'

export default defineComponent({
  name: 'TapPaySettins',
  components: { TapPayEditModal },
  setup (props) {
    const { shopId } = useShop()
    const loading = ref(false)
    const configData = ref({})
    const modal = reactive({
      edit: false,
    })
    const displayData = computed(() => {
      const config = configData.value
      return [
        { label: '是否啟用 iPhone 卡緊收收款', value: get(config, 'enabled') ? '開啟' : '關閉' },
        { label: 'TapPay Partner Account', value: get(config, 'partnerAccount') },
        { label: 'TapPay Partner Key', value: get(config, 'partnerKey') },
        { label: '卡緊收一般收款綁定', value: renderName(get(config, 'AllowedSalesPaymentUsers')) },
        { label: '卡緊收快速收款綁定', value: renderName(get(config, 'AllowedDirectPaymentUsers')) },
      ]
    })
    const allowBindUserList = ref([])

    const getTapPayConfig = async () => {
      loading.value = true
      const [res, err] = await GetTapPayConfig({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      loading.value = false
      configData.value = res
    }

    const findAllowBindUserList = async () => {
      const [res, err] = await FindAllowBindUserList({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      allowBindUserList.value = res
    }
    const renderName = (array) => {
      if (array && array.length) {
        const list = array.map(item => `${item.name} (${item.id.slice(-5)})`)
        return list.join('、')
      } else {
        return '-'
      }
    }
    const refresh = async () => {
      await findAllowBindUserList()
      await getTapPayConfig()
    }
    onMounted(async () => {
      refresh()
    })
    return {
      loading,
      displayData,
      modal,
      configData,
      refresh,
    }
  },
})
</script>
<style lang="postcss" scoped>
::v-deep .el-input, ::v-deep .el-switch, ::v-deep .el-select {
  @apply w-full
}
</style>
